
// import FallButton from './FallButton';

function AboutBox() {
    return (
        <div className={"text-center p-2 shadow m-2 text-center"} style={{
            color: "darkblue",
            background:
              "linear-gradient( rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) ),url('/introBack.jpeg')",
              borderRadius:"5px",
              fontSize:"20px"
          }}>
    Amirrezashams.ir</div>
    );
  }
  
  export default AboutBox;
  