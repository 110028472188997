
// import FallButton from './FallButton';

function IntroBox() {
  return (
      <div className={"d-flex text-center p-2 shadow"} style={{background:"linear-gradient( rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3) )", color:"darkblue",fontSize:20, "borderRadius":"5px"}}>
        ای حافظ شیرازی! تو محرم هر رازی! تو را به خدا و به شاخ نباتت قسم می دهم که هر چه صلاح و مصلحت می بینی برایم آشکار و آرزوی مرا براورده سازی.  برای شادی روح حافظ، صلوات یا فاتحه ای نثار نماییم!</div>
  );
}

export default IntroBox;
