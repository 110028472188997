import FalBox from './FalBox';
import IntroBox from './IntroBox';
import FalButton from './FalButton';
import AboutBox from './AboutBox';
import React, { useState } from 'react';

function App() {
  const [visibleFal, setVisibleFal] = useState(false);
  const [Fal, setFal] = useState("");
  return (
    <div style={{"backgroundColor":"#dcc69d","backgroundImage":"url('/background.svg')"}}>
      
      <div className="container" style={{minHeight:"100vh"}}>
        <div className="pt-4 pb-2">

      <IntroBox/>
      <FalBox visible={visibleFal} FalText={Fal}/>
      <FalButton setVisible={setVisibleFal} setFalText={setFal}/>
      <AboutBox/>
        </div>
      </div>
    </div>
  );
}

export default App;
