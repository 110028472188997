function FalBox(props) {
  return (
    <div
      className={props.visible ? "mt-3 d-flex text-center p-2 shadow" : ""}
      style={
        props.visible
          ? {
              background:
                "linear-gradient( rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3) )",
              color: "darkblue",
              fontSize: 20,
              borderRadius: "5px",
              wordWrap:"breakWord"
            }
          : { visibility: "hidden !important" }
      }
      dangerouslySetInnerHTML={{__html:props.FalText}}
    >
        {}
    </div>
  );
}

export default FalBox;
